import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private _snackbar: MatSnackBar
  ) { }

  error(message: string){
    return this._snackbar.open(message, undefined, {duration: 3000})
  }

  success(message: string){
    return this._snackbar.open(message, undefined, {duration: 3000})
  }

  info(message: string){
    return this._snackbar.open(message, undefined, {duration: 3000})
  }
}
