import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserFS } from 'src/app/core/model/user';
import { map } from 'rxjs/operators';

// lifespan = 1 month in miliseconds
const NOTIFICATION_TOKEN_LIFESPAN = 1000*60*60*24*30

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject = new BehaviorSubject<UserFS | undefined>(undefined);
  user$ = this.userSubject.asObservable();

  constructor(private db: AngularFirestore) {}

  getUserByUID(uid: string): Observable<UserFS> {
    return this.db
      .collection('retail_users')
      .doc(uid)
      .get()
      .pipe(map((user: any) => new UserFS(user.data())));
  }

  getCurrentUser() {
    return this.userSubject.value;
  }

  setCurrentUser(user: UserFS | undefined) {
    this.userSubject.next(user);
  }

  userAsObservable(): Observable<UserFS | undefined> {
    return this.user$;
  }

  createUser(userData: UserFS, formValue: any) {
    let user = new UserFS({
      ...userData,
      ...formValue,
      orgId: '',
      creationTimestamp: new Date(),
    });
    return this.db.collection('retail_users').doc(user.id).set(user.toFireStore());
  }

  updateUser(uid: string) {
    this.db
      .collection('retail_users')
      .doc(uid)
      .get()
      .pipe(
        map((user: any) => {
          new UserFS(user.data());
          this.userSubject.next(user);
        })
      );
  }

  activateUser(user: UserFS) {
    return this.db
      .collection('retail_users')
      .doc(user.id)
      .update({deleted: false})
  }

  saveNotificationToken(token: string): void {
    const currentUser = this.userSubject.value
    console.log(currentUser)
    if (!currentUser || !currentUser.id) return;
    const currentToken = currentUser.notificationToken;
    if (
      !currentToken.token ||
      (new Date()).getTime() - (new Date(currentToken.timestamp).getTime()) > NOTIFICATION_TOKEN_LIFESPAN ||
      currentToken.token != token
      ) {
      console.log('Saving token changes')
      this.db.collection('retail_users').doc(currentUser.id).set({
        notificationToken: { timestamp: (new Date()).toDateString(), token }
      }, { merge: true })
    }
  }
}
