import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/auth/services/user.service';
import { UserFS } from '../../model/user';
import { NavigationService } from '../../services/navigation.service';
import { Store } from '@ngxs/store';
import { RetailInvoicesActions } from 'src/app/expenses/states/retail-invoices/retail-invoices.actions';
import { Disconnect } from '@ngxs-labs/firestore-plugin';
import { MessagingService } from '../../services/messaging.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.sass'],
})
export class NavigationComponent implements OnInit {
  routes = [
    {
      path: '/app/expenses',
      name: 'Računi',
    },
    {
      path: '/app/expenses/onboard',
      name: 'Tutorijal',
    },
  ];

  title = 'Tefter';
  userReady = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private navigationService: NavigationService,
    private userService: UserService,
    private store: Store,
    private messagingService: MessagingService
  ) {
    this.navigationService.startSaveHistory();
  }

  ngOnInit(): void {
    this.userService.userAsObservable().subscribe((user: UserFS | undefined) => {
      if (!user) return;
      if (user.id) {
        this.userReady = true;
        this.messagingService.requestPermisison();
      }
    });
  }

  signIn() {
    this.router.navigate(['/auth']);
  }

  signOut() {
    this.store.dispatch(new RetailInvoicesActions.Clear()).subscribe(() => {
      this.authService.signOut();
    });
  }

  navigateBack() {
    this.navigationService.goBack();
  }
}
