import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserEditService } from '../../services/user-edit/user-edit.service';
import { UserService } from 'src/app/auth/services/user.service';
import { UserFS } from 'src/app/core/model/user';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Store } from '@ngxs/store';
import { RetailInvoicesActions } from 'src/app/expenses/states/retail-invoices/retail-invoices.actions';

@Component({
  selector: 'app-edit-info',
  templateUrl: './edit-info.component.html',
  styleUrls: ['./edit-info.component.sass'],
})
export class EditInfoComponent implements OnInit {
  @Input() firstTimeLogin: boolean = false;
  @Input() set userFS(value: UserFS) {
    this._userFS = value;
    this.initUserForm(value);
  }
  _userFS!: UserFS;
  @Output() createUser: EventEmitter<any> = new EventEmitter();
  userInfo = this.formBuilder.group({
    phoneNumber: [{ value: '', disabled: true }],
    firstName: [''],
    lastName: [''],
    dateOfBirth: [{ value: '', disabled: true }],
    gender: [''],
    termsOfUse: [false, Validators.requiredTrue],
  });

  constructor(
    private formBuilder: FormBuilder,
    private userEditService: UserEditService,
    private userService: UserService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private store: Store,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (!this.firstTimeLogin) {
      let user: UserFS = this.userService.getCurrentUser() || new UserFS();
      this.initUserForm(user);
    }
  }

  initUserForm(user: UserFS) {
    let firstNameAndLastName = user.fullName.split(' ');
    let firstName = firstNameAndLastName.length > 0 ? firstNameAndLastName[0] : '';
    let lastName = firstNameAndLastName.length > 1 ? firstNameAndLastName[1] : '';
    let dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    let dateOfBirth = new Date();
    if (dateRegex.test(user.dateOfBirth)) {
      let date = user.dateOfBirth.split('/');
      dateOfBirth = new Date(`${date[2]}-${date[1]}-${date[0]}`);
    }
    this.userInfo.setValue({
      phoneNumber: user.phoneNumber,
      firstName,
      lastName,
      dateOfBirth,
      gender: user.gender,
      termsOfUse: false,
    });
  }

  async updateUser() {
    this.spinnerService.showSpinner();
    let firstName = this.userInfo.controls['firstName'].value.trim();
    let lastName = this.userInfo.controls['lastName'].value.trim();
    let dateOfBirth = this.userInfo.controls['dateOfBirth'].value.toLocaleDateString();
    let gender = this.userInfo.controls['gender'].value;
    let userId = this.userService.getCurrentUser()?.id || "";
    if (!this.firstTimeLogin) {
      await this.userEditService.updateUserDetails(userId, `${firstName} ${lastName}`, dateOfBirth, gender);
      this.userInfo.patchValue({
        termsOfUse: false,
      });
      this.spinnerService.stopSpinner();
      this.userService.updateUser(userId);
    } else {
      this.createUser.emit({
        fullName: `${firstName} ${lastName}`,
        gender,
        dateOfBirth,
      });
    }
  }

  deleteAccount() {
    const message = `Da li ste sigurni da želite da deaktivirate nalog?`;
    const dialogData = { message };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.spinnerService.showSpinner;
        let userId = this.userService.getCurrentUser()?.id || "";
        this.userEditService.deleteUser(userId).then(() => {
          this.spinnerService.stopSpinner();
          
          this.store.dispatch(new RetailInvoicesActions.Clear()).subscribe(() => {
            this.authService.signOut();
          });
        });
      }
    });
  }
}
