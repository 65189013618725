import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthModule } from '../../auth.module';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-reactivate',
  templateUrl: './reactivate.component.html',
  styleUrls: ['./reactivate.component.sass']
})
export class ReactivateComponent implements OnInit {

  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  reactivateAccount() {
    const user = this.userService.getCurrentUser();
    if (!user) return;
    this.userService.activateUser(user).then(() => {
      this.router.navigateByUrl('/app')
    })
  }

  logout() {
    this.authService.signOut();
  }

}
