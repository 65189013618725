import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class UserEditService {
  constructor(private firestore: AngularFirestore) {}

  updateUserDetails(userId: string, fullName: string, dateOfBirth: string, gender: string) {
    return this.firestore.collection('retail_users').doc(userId).set({ fullName, dateOfBirth, gender }, { merge: true });
  }

  deleteUser(userId: string) {
    return this.firestore
      .collection('retail_users')
      .doc(userId)
      .set({ deleted: true, deletedDate: new Date() }, { merge: true });
  }
}
