import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { CodeComponent } from './components/code/code.component';
import { PhoneComponent } from './components/phone/phone.component';
import { SharedModule } from '../shared/shared.module';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { AuthGuard } from './guards/auth.guard';

import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/functions';
import { RouterModule, Routes } from '@angular/router';
import { ReactivateComponent } from './components/reactivate/reactivate.component';
import { UserModule } from '../user/user.module';

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'phone',
        component: PhoneComponent,
      },
      {
        path: 'code',
        component: CodeComponent,
      },
      {
        path: 'register-user',
        component: RegisterUserComponent,
      },
      {
        path: 'reactivate',
        component: ReactivateComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/auth/phone'
      },
    ]
  }
];

@NgModule({
  declarations: [
    CodeComponent,
    PhoneComponent,
    RegisterUserComponent,
    ReactivateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    RouterModule.forChild(routes),
    UserModule,
  ],
  exports: [AngularFireModule, AngularFirestoreModule, AngularFireAuthModule],
  providers: [
    AngularFireModule,
    AuthGuard,
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8082] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
  ],
})
export class AuthModule {}
