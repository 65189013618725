import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { InfoDialogComponent } from 'src/app/core/components/info-dialog/info-dialog.component';
import { UserFS } from 'src/app/core/model/user';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { WindowService } from '../../services/window.service';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.sass'],
})
export class CodeComponent implements OnInit {
  phoneNumber!: string;
  windowRef: any;

  @ViewChild('codeField', { static: true }) codeField!: ElementRef;

  form!: FormGroup;
  showProgress!: boolean;

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private win: WindowService,
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService
  ) {
    this.form = this.fb.group({
      code: ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
    });
  }

  ngOnInit(): void {
    this.windowRef = this.win.windowRef;
    if (!this.windowRef.confirmationResult) {
      this.router.navigate(['/auth/phone']);
    }

    this.phoneNumber = this.windowRef.signinPhoneNumber;

    this.showProgress = false;
  }

  verifyAuthCode() {
    if (this.form.valid) {
      this.form.disable();
      this.showProgress = true;
      const verificationCode = this.form.value.code;
      from(this.authService.verifyLoginCode(verificationCode)).subscribe(
        (result) => {
          this.redirectAfterLogin();
        },
        (error) => {
          this.dialog.open(InfoDialogComponent, {
            width: '360px',
            data: { text: error.message },
          });
          this.form.enable();
          this.showProgress = false;
        }
      );
    }
  }

  redirectAfterLogin() {
    this.authService.loggedIn$.subscribe((isLoggedIn: boolean) => {
      if (!isLoggedIn) this.router.navigate(['/auth/phone']);
      this.userService.userAsObservable().subscribe((user: UserFS | undefined) => {
        console.log('User', user)
        if (!user) return;
        console.log('1')
        if (!user.id) {
          console.log('2')
          this.router.navigate(['/auth', 'register-user'])
        } else {
          console.log('3')
          if (user.deleted) {
            console.log('redirect reactivate')
            this.router.navigate(['/auth', 'reactivate'])
            return;
          }
          console.log('redirect app')
          this.router.navigate(['/app'])
        }
      });
    });
  }

  resendCode() {
    this.form.disable();
    this.showProgress = true;
    const appVerifier = this.windowRef.signinAppVerifier;

    from(this.authService.sendLoginCode(this.phoneNumber, appVerifier)).subscribe(
      () => {
        this.form.enable();
        this.showProgress = false;
        this.form.reset();
        this.codeField.nativeElement.focus();
      },
      (error) => {
        this.dialog.open(InfoDialogComponent, {
          width: '360px',
          data: { text: error.message },
        });
        this.form.enable();
        this.showProgress = false;
      }
    );
  }
}
