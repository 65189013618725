<div id="recaptcha-container"></div>
<div class="auth-form-container">
  <mat-card class="auth-form-block">
    <div class="auth-form-progress">
      <mat-progress-bar *ngIf="showProgress" color="accent" mode="indeterminate"></mat-progress-bar>
    </div>
    <form [formGroup]="form" (ngSubmit)="verifyAuthCode()" [ngClass]="{'form-blocked': showProgress}">
      <div class="auth-form-logo text-center">
        <img src="assets/images/mnabavka-graphic1.jpg">
    </div>
      <h2 class="text-center">Prijavite se</h2>
      <p>SMS poruka sa 6-cifrenim verifikacionim kodom je upravo poslata na broj <strong>{{phoneNumber}}</strong></p>
      <mat-form-field class="auth-form-input width-100">
        <input matInput placeholder="Verifikacioni kod" formControlName="code" #codeField autocomplete="off" autofocus inputmode="numeric">
        <mat-error *ngIf="form.controls['code'].hasError('required')">Ovo polje je obavezno</mat-error>
        <mat-error *ngIf="form.controls['code'].hasError('pattern')">Ovo polje mora sadrzati 6 cifara</mat-error>
      </mat-form-field>
      <div class="resend-block">
        <p>Kod nije stigao? <span class="resend-link" (click)="resendCode()">Pošalji ponovo</span></p>
      </div>
      <div class="auth-form-actions">
        <div>
          <a routerLink="/auth/phone" mat-button color="accent">Nazad</a>
        </div>
        <div class="text-rigth">
          <button type="submit" mat-raised-button color="accent">Potvrdi</button>
        </div>
      </div>
    </form>
  </mat-card>
</div>
