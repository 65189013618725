import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { Route, RouterModule } from '@angular/router';
import { EditInfoComponent } from './components/edit-info/edit-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Route[] = [
  {
    path: 'editInfo',
    component: EditInfoComponent,
  },
];

@NgModule({
  declarations: [EditInfoComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(routes), SharedModule],
  exports: [EditInfoComponent],
})
export class UserModule {}
