import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { trace } from 'console';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/auth/services/user.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  token$: Observable<any>;
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private userService: UserService,
    private snackbar: SnackbarService
  ) { 
    console.log('Messaging constructor')
    this.token$ = angularFireMessaging.tokenChanges.pipe(
      (token: any) => { console.log('token', token); return token}
    );
  }

  requestPermisison() {
    console.log('Requesting permission')
    this.angularFireMessaging.requestPermission.subscribe(
      () => { console.log('Permission granted') },
      (error) => { console.log(error); }
    )
    this.angularFireMessaging.getToken.subscribe((token: any) => {
      console.log('Saving NotificationToken')
      this.userService.saveNotificationToken(token);
    })
    this.angularFireMessaging.onMessage((message: any) => { 
      console.log("New message", message)
      this.handleMessage(message);
    })
  }

  messages(): Observable<any> {
    return this.currentMessage.asObservable();
  }

  handleMessage(message: any) {
    switch (message.data.type) {
    case "RETAIL_INVOICE_DUPLICATE":
      this.snackbar.error(message.notification.body)
      break;
    default:
      this.snackbar.info(message.notification.body)
    }
    this.currentMessage.next(message) 

  }

  
}
