import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { NavigationComponent } from './components/navigation/navigation.component';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PwaPromptComponent } from './components/pwa-prompt/pwa-prompt.component';

@NgModule({
  declarations: [InfoDialogComponent, NavigationComponent, SpinnerComponent, ConfirmDialogComponent, PwaPromptComponent],
  imports: [CommonModule, SharedModule, RouterModule, OverlayModule],
})
export class CoreModule {}
