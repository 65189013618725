import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, from } from 'rxjs';
import { UserFS } from 'src/app/core/model/user';
import { UserService } from './user.service';
import { WindowService } from './window.service';
import 'firebase/auth';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userAF: any;
  tmpUserData: any;
  private loggedInSubject = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedInSubject.asObservable();

  windowRef: any;

  constructor(
    private afAuth: AngularFireAuth,
    private win: WindowService,
    private spinnerService: SpinnerService,
    public router: Router,
    private userService: UserService
  ) {
    this.windowRef = this.win.windowRef;

    this.spinnerService.showSpinner();
    this.afAuth.onAuthStateChanged((user: any) => {
      this.userAF = user;
      if (user) {
        this.loggedInSubject.next(true);
        this.getUserData().subscribe(() => this.spinnerService.stopSpinner());
      } else {
        this.loggedInSubject.next(false);
        this.userService.setCurrentUser(undefined);
        this.spinnerService.stopSpinner();
      }
    });
  }

  private getUserData(): any {
    return this.userService.getUserByUID(this.userAF.uid).pipe(
      map((userFS: UserFS) => {
        this.userService.setCurrentUser(userFS);
        // if (userFS.id) {
        //   this.router.navigate(['/app']);
        // }
      })
    );
  }

  public processNewUser(user: UserFS): UserFS {
    if (user == undefined || !user.id) {
      user = new UserFS({
        id: this.userAF?.uid,
        phoneNumber: this.userAF?.phoneNumber,
      });
    }
    return user;
  }

  private userSubject = new BehaviorSubject<any>({});
  user$ = this.userSubject.asObservable();

  isLoggedIn(): boolean {
    return this.userAF ? true : false;
  }

  getCurrentUser() {
    // return this.user
    return this.userAF;
  }

  sendLoginCode(phone: string, appVerifier: any) {
    this.windowRef.signinPhoneNumber = phone;
    this.windowRef.signinAppVerifier = appVerifier;
    return new Promise<any>((resolve, reject) => {
      this.afAuth
        .signInWithPhoneNumber(phone, appVerifier)
        .then((result) => {
          this.windowRef.confirmationResult = result;
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  verifyLoginCode(code: any) {
    return new Promise<any>((resolve, reject) => {
      this.windowRef.confirmationResult
        .confirm(code)
        .then((result: any) => {
          resolve(result);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  signOut() {
    return from(
      this.afAuth.signOut().then(() => {
        this.userService.setCurrentUser(undefined)
        this.router.navigate(['auth/phone']);
      })
    );
  }
}
