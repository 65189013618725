<div id="recaptcha-container"></div>
<div class="auth-form-container">
    <mat-card class="auth-form-block">
        <div class="auth-form-progress">
            <mat-progress-bar *ngIf="showProgress" color="accent" mode="indeterminate"></mat-progress-bar>
        </div>
        <form [formGroup]="form" (ngSubmit)="sendAuthCode()" [ngClass]="{'form-blocked': showProgress}">
            <div class="auth-form-logo text-center">
                <img src="assets/images/mnabavka-graphic1.jpg">
            </div>
            <h2 class="text-center">Prijavite se</h2>
            <mat-form-field class="auth-form-input width-100">
                <input matInput placeholder="Broj telefona" formControlName="phoneNumber" autocomplete="off" autofocus inputmode="numeric">
                <mat-error *ngIf="form.controls.phoneNumber.hasError('requred')">Ovo polje je obavezno</mat-error>
                <mat-error *ngIf="form.controls.phoneNumber.hasError('invalid')">Unesite validan broj telefona</mat-error>
            </mat-form-field>
            <div class="auth-form-action">
                <button type="submit" mat-raised-button color="accent">Potvrdi</button>
            </div>
        </form>

    </mat-card>
</div>