import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { WindowService } from '../../services/window.service';
import { CustomValidators as Validators } from 'src/app/core/validators/custom.validators';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { from } from 'rxjs';
import { InfoDialogComponent } from 'src/app/core/components/info-dialog/info-dialog.component';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.sass']
})
export class PhoneComponent implements OnInit {

  windowRef: any;
  form: any;
  showProgress: any;

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private win: WindowService,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.phone('RS')]],
    });    
    
   }

  ngOnInit() {
    this.windowRef = this.win.windowRef;

    if(firebase.apps.length === 0){
      firebase.initializeApp(environment.firebase);
    }
      
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible'
    });
    this.showProgress = false;
  }

  sendAuthCode() {
    if (this.form.valid) {
      this.showProgress = true;
      this.form.disable();
      const appVerifier = this.windowRef.recaptchaVerifier;
      const phone = parsePhoneNumberFromString(this.form.value.phoneNumber, 'RS');

      from(this.authService.sendLoginCode(phone!.number.toString(), appVerifier))
        .subscribe(() => {
          this.router.navigate(['/auth/code']);
        }, error => {
          this.dialog.open(InfoDialogComponent, {
            width: '360px',
            data: { text: error.message }
          });
          this.form.enable();
          this.showProgress = true;
        })
    }
  }

}
