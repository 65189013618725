<div class="formWrapper">
  <form>
    <mat-card layout="column" class="formContainer">

      <mat-card-title class="formTitle">Aktivirajte nalog</mat-card-title>

      <mat-card-content class="formContent">
        Nalog sa ovim brojem telefona je obrisan. Da li želite da ga ponovo aktivirate?
      </mat-card-content>

      <div class="formField formButton">
        <button mat-raised-button color="primary" (click)="reactivateAccount()">
          Aktiviraj
        </button>
        <button class="delete" mat-raised-button color="warn" (click)="logout()">Promeni nalog</button>
      </div>
    </mat-card>
 </form>
</div>