import { IRetailInvoice } from "src/app/core/model/retailInvoice"


export namespace RetailInvoicesPayloads {
    export type Create = Partial<IRetailInvoice>
    export type GetPages = number;
    export type Delete = string;
}

export namespace RetailInvoicesActions {

    export class GetAll {
        public static readonly type = '[RetailInvoices] GetAll'
    }

    export class Create {
        public static readonly type = '[RetailInvoices] Create'
        constructor(public payload: RetailInvoicesPayloads.Create) {}
    }

    export class GetPages {
        public static readonly type = '[RetailInvoices] GetPages'
        constructor(public payload: RetailInvoicesPayloads.GetPages) {}
    }

    export class NextPage {
        public static readonly type = '[RetailInvoice] NextPage'
    }

    export class Clear {
        public static readonly type = '[RetailInvoice] Clear'
    }

    export class Delete {
        public static readonly type = '[RetailInvoice] Delete'
        constructor(public payload: RetailInvoicesPayloads.Delete) {}
    }
}