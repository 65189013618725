import { NotificationToken } from "./notification-token.model";

export interface User {
  fullName: string;
  id: string;
  orgId: string;
  phoneNumber: string;
}

export interface UserSettings extends User {
  accountType: string;
  catalogId: string;
  isActivated: boolean;
  salesRepMatchId?: Array<string>;
}

export interface IUserFS {
  creationTimestamp: string;
  fullName: string;
  id: string;
  orgId: string;
  phoneNumber: string;
  queryTaxId: string;
  terms: boolean;
  dateOfBirth: string;
  gender: string;
  deleted: boolean;
  deletedDate?: Date;
  notificationToken: NotificationToken
}

export class UserFS implements IUserFS {
  creationTimestamp: string;
  fullName: string;
  id: string;
  orgId: string;
  phoneNumber: string;
  queryTaxId: string;
  terms: boolean;
  dateOfBirth: string;
  gender: string;
  deleted: boolean;
  deletedDate?: Date | undefined;
  notificationToken: NotificationToken;

  constructor(obj?: any) {
    this.creationTimestamp = (obj && obj.creationTimestamp) || '';
    this.fullName = (obj && obj.fullName) || '';
    this.id = (obj && obj.id) || '';
    this.orgId = (obj && obj.orgId) || '';
    this.phoneNumber = (obj && obj.phoneNumber) || '';
    this.queryTaxId = (obj && obj.queryTaxId) || '';
    this.terms = (obj && obj.terms) || false;
    this.dateOfBirth = (obj && obj.dateOfBirth) || '';
    this.gender = (obj && obj.gender) || '';
    this.deleted = (obj && obj.deleted) || false;
    this.deletedDate = (obj && obj.deletedDate) || undefined;
    this.notificationToken = obj && new NotificationToken(obj.notificationToken) || new NotificationToken();
  }

  toFireStore() {
    return {
      creationTimestamp: this.creationTimestamp,
      fullName: this.fullName,
      id: this.id,
      orgId: this.orgId,
      phoneNumber: this.phoneNumber,
      queryTaxId: this.queryTaxId,
      dateOfBirth: this.dateOfBirth,
      gender: this.gender,
      terms: this.terms,
      deleted: this.deleted,
      deletedDate: this.deletedDate,
      notificationToken: this.notificationToken.toFirestore()
    };
  }
}

// collection organzations/:orgId/users
export interface IOrgUser {
  catalog: string;
  fullName: string;
  id: string;
  isActive: boolean;
  orgId: string;
  phoneNumber: string;
  roles: string[];
  salesRepRefCode: string[];
  warehouses: string[];
}

export class OrgUser {
  catalog: string;
  fullName: string;
  id: string;
  isActive: boolean;
  orgId: string;
  phoneNumber: string;
  roles: string[];
  salesRepRefCode: string[];
  warehouses: string[];

  constructor(obj?: any) {
    this.catalog = (obj && obj.catalog) || '';
    this.fullName = (obj && obj.fullName) || '';
    this.id = (obj && obj.id) || '';
    this.isActive = (obj && obj.isActive) || false;
    this.orgId = (obj && obj.orgId) || '';
    this.phoneNumber = (obj && obj.phoneNumber) || '';
    this.roles = (obj && obj.roles) || [];
    this.salesRepRefCode = (obj && obj.salesRepRefCode) || [];
    this.warehouses = (obj && obj.warehouses) || [];
  }

  toFirestore() {
    return {
      catalog: this.catalog,
      fullName: this.fullName,
      isActive: this.isActive,
      id: this.id,
      orgId: this.orgId,
      phoneNumber: this.phoneNumber,
      roles: this.roles,
      salesRepRefCode: this.salesRepRefCode,
      warehouses: this.warehouses,
    };
  }
}
