<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [style.height]="'auto'">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <ng-container *ngFor="let route of routes">
        <a
          mat-list-item
          [routerLink]="[route.path]"
          (click)="drawer.toggle()"
          >{{ route.name }}</a
        >
      </ng-container>
      <mat-divider></mat-divider>
      <a mat-list-item [routerLink]="'/app/user/editInfo'" (click)="drawer.toggle()">Profil</a>
      <a mat-list-item (click)="signOut()">Odjavi se</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span [style.overflow]="'hidden'">{{ title }}</span>
      <span class="flexExpand"></span>
      <button mat-button (click)="navigateBack()" type="button">
        <mat-icon appClickStopPropagation (click)="navigateBack()"
          >arrow_back</mat-icon
        >
      </button>
    </mat-toolbar>
    <router-outlet *ngIf="userReady"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<!--
          [opened]="(isHandset$ | async) === true"
  [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'over'"
            *ngIf="isHandset$ | async"
   -->
