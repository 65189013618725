<form [formGroup]="userInfo">
  <div class="formWrapper">
    <mat-card layout="column" class="formContainer">
      <img src="assets/images/mnabavka-graphic1.jpg" />
      <mat-card-title class="formTitle"> Promenite informacije o nalogu </mat-card-title>
      <mat-card-content class="formContent">
        <mat-form-field class="formField" appearance="outline">
          <mat-label>Broj telefona</mat-label>
          <input matInput formControlName="phoneNumber" />
          <mat-icon matSuffix>phone</mat-icon>
        </mat-form-field>
        <mat-form-field class="formField" appearance="outline">
          <mat-label>Ime</mat-label>
          <input matInput formControlName="firstName" />
          <mat-icon matSuffix>person icon</mat-icon>
        </mat-form-field>
        <mat-form-field class="formField" appearance="outline">
          <mat-label>Prezime</mat-label>
          <input matInput formControlName="lastName" />
          <mat-icon matSuffix>person icon</mat-icon>
        </mat-form-field>
        <mat-form-field class="formField datePicker" appearance="outline">
          <mat-label>Datum rođenja</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" [attr.disabled]="true" />
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker [disabled]="false"></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="formField" appearance="outline">
          <mat-label>Pol</mat-label>
          <mat-select formControlName="gender">
            <mat-option value="men">Muški</mat-option>
            <mat-option value="woman">Ženski</mat-option>
            <mat-option value="unspecified">Neopredeljen/a</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="formField">
          <mat-checkbox formControlName="termsOfUse">
            Prihvatam <span><a href="/">opšte uslove poslovanja</a></span>
          </mat-checkbox>
        </div>
        <div class="formField formButton">
          <button mat-raised-button color="primary" (click)="updateUser()" [disabled]="userInfo.invalid">
            Sačuvaj
          </button>
          <button *ngIf="!firstTimeLogin" class="delete" mat-raised-button color="warn" (click)="deleteAccount()">
            Obriši nalog
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</form>
