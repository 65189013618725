import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private history: string[] = [];

  constructor(
    private router: Router,
  ) { }

  public startSaveHistory(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const top = this.history[this.history.length - 1]
        const newUrl = event.urlAfterRedirects
        if (newUrl != top) {
          this.history.push(newUrl)

        }
      }
    })
  }

  public getHistory(): string[] {
    return this.history;
  }

  public goBack(): void {

    if (this.history.length > 0) {
      const prevoiusUrl = this.history.pop() || "/";
      this.router.navigateByUrl(prevoiusUrl);
    } else {
      this.router.navigateByUrl('/')
    }
  }


}
