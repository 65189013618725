import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserFS } from 'src/app/core/model/user';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.sass'],
})
export class RegisterUserComponent implements OnInit {
  userAF: any;
  user: UserFS = new UserFS();

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.userAF = this.authService.getCurrentUser();
    this.user = new UserFS({
      id: this.userAF.uid,
      phoneNumber: this.userAF.phoneNumber,
    });
  }

  async registerUser(userInfo: any) {
    this.userService.createUser(this.user, userInfo).then((result: any) => {
      this.userService.getUserByUID(this.user.id).subscribe((user: UserFS) => {
        this.userService.setCurrentUser(user);
        this.router.navigate([`/app`]);
      })
    })
  }

  createUser(userInfo: any) {
    this.registerUser(userInfo);
    this.spinnerService.stopSpinner();
  }
}
